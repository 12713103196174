import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import HomePage from "./pages/HomePage";
import OnlinePage from "./pages/OnlinePage";
import MysteerioPage from "./pages/MysteerioPage";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={HomePage} />
        <Route path="/online" Component={OnlinePage} />
        <Route path="/mysteerio" Component={MysteerioPage} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
