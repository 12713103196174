import React from "react";
import "../App.css";
import { Project } from "../types";
import onlineImage from "../img/online.png";

export const online: Project = {
  title: "OnLine",
  image: onlineImage,
  description:
    "OnLine is a small-sized IT company from Yaroslavl, Russia.\nThe website is developed from scratch but the layout idea was taken from Bootstrap Creative template.\nRole: Front-end Developer, Back-end Developer, Designer.",
  link: "http://optinet.ru/",
};

const OnlinePage: React.FC = () => {
  return (
    <>
      <a href="/" id="logo">
        MP
      </a>
      <div className="projectPage">
        <div className="leftPart">
          <img src={online.image} alt={online.title} />
        </div>
        <div className="rightPart">
          <h1>{online.title}</h1>
          <p>{online.description}</p>
          <a href={online.link} target="_blank" rel="noopener noreferrer">
            Visit the website
          </a>
        </div>
      </div>
    </>
  );
};

export default OnlinePage;
