import React from "react";
import {
  FaAws,
  FaCss3Alt,
  FaDocker,
  FaGithub,
  FaJava,
  FaJsSquare,
  FaLinkedinIn,
  FaLinux,
  FaReact,
  FaShieldAlt,
  FaTelegramPlane,
  FaVuejs,
} from "react-icons/fa";
import { SiCisco, SiMongodb, SiVitest } from "react-icons/si";
import "../App.css";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { online } from "./OnlinePage";
import { mysteerio } from "./MysteerioPage";
import { Link } from "react-router-dom";

const HomePage: React.FC = () => {
  return (
    <>
      <header className="greeting">
        <h4 id="hi">Hi! I am</h4>
        <h1>Maksim Paderin</h1>
        <p>A Finnish software developer and a network engineer.</p>
      </header>
      <a href="/" id="logo">
        MP
      </a>
      <div className="skills">
        <div className="skillBlock">
          <h3>Networking & Infra</h3>
          <div className="skillIcons">
            <Tooltip title="Linux" placement="top">
              <FaLinux size={"56px"} />
            </Tooltip>
            <Tooltip title="Docker" placement="top">
              <FaDocker size={"56px"} />
            </Tooltip>
            <Tooltip title="AWS" placement="top">
              <FaAws size={"56px"} />
            </Tooltip>
            <Tooltip title="Mongo / PostgreSQL" placement="top">
              <SiMongodb size={"56px"} />
            </Tooltip>
            <Tooltip title="Cisco" placement="top">
              <SiCisco size={"56px"} />
            </Tooltip>
            <Tooltip title="Cybersecurity" placement="top">
              <FaShieldAlt size={"56px"} />
            </Tooltip>
          </div>
        </div>
        <div className="skillBlock">
          <h3>Software Development</h3>
          <div className="skillIcons">
            <Tooltip title="CSS" placement="top">
              <FaCss3Alt size={"56px"} />
            </Tooltip>
            <Tooltip title="JavaScript" placement="top">
              <FaJsSquare size={"56px"} />
            </Tooltip>
            <Tooltip title="Vue.js" placement="top">
              <FaVuejs size={"56px"} />
            </Tooltip>
            <Tooltip title="React" placement="top">
              <FaReact size={"56px"} />
            </Tooltip>
            <Tooltip title="Java" placement="top">
              <FaJava size={"56px"} />
            </Tooltip>
            <Tooltip title="ViTest" placement="top">
              <SiVitest size={"56px"} />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="description">
        <p>
          Currently I work as a Software Developer in Kustannusosakeyhtiö Otava,
          Helsinki, Finland.
        </p>
        <p>My projects include:</p>
      </div>
      <div className="projects">
        <Link to="/online">
          <img src={online.image} alt={online.title} />
        </Link>
        <Link to="/mysteerio">
          <img src={mysteerio.image} alt={mysteerio.title} />
        </Link>
      </div>
      <div className="centeredText">
        <p>Me on the internet</p>
      </div>
      <div className="links">
        <a href="https://github.com/mvpaderin" rel="noreferrer" target="_blank">
          <FaGithub size={"38px"} />
        </a>
        <a
          href="https://www.linkedin.com/in/makspaderin"
          rel="noreferrer"
          target="_blank">
          <FaLinkedinIn size={"38px"} />
        </a>
        <a
          href="https://telegram.me/mvpaderin"
          rel="noreferrer"
          target="_blank">
          <FaTelegramPlane size={"38px"} />
        </a>
      </div>
      <p id="copyright">© 2025 Maksim Paderin</p>
    </>
  );
};

export default HomePage;
